.page-title {
  display: flex;
}

.page-content {
  padding-bottom: 8em;
}

.header .nav-item {
  padding-right: 0 !important;
}

.header .header-toggler {
  margin-left: 0.25rem !important;
}

.header-profile {
  align-items: center;
  display: flex;
}

@keyframes shine {
  0% { background-position: -500px; }
  100% { background-position: 500px; }
}

.donator-highlight {
  background: linear-gradient(20deg, rgb(248, 239, 191), rgb(156, 134, 11), rgb(248, 239, 191));
  background-size: 200%;
  animation: shine 30s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dark .donator-highlight {
  background-image: linear-gradient(30deg, transparent, gold, transparent);
}

.header-region {
  font-size: 0.7rem;
  line-height: 0.7rem;
}

.username-input-wrapper {
  font-weight: 400;
  line-height: 2.5rem;
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 1.5rem;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icon-spin {
  -o-animation: spin 3s infinite linear;
  animation: spin 3s infinite linear;
}

.username-input-wrapper button {
  padding-left: 0.5rem !important;
  margin-left: 1rem;
}

.user-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 200px;
}

.username-input-wrapper input {
  max-width: 250px;
}

.page-title {
  flex-grow: 1;
}

.header .container {
  padding-right: 0.5rem;
}

.header-wrapper {
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.header-title {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.header-fetch-wrapper {
  display: flex;
  align-items: center;
}

.header-fetch-wrapper button {
  height: 30px;
}

.header-fetch-wrapper input {
  max-width: 130px;
}

.top-row .card .text-right {
  visibility: hidden;
}

.flex-grow {
  flex-grow: 1;
}

.medal-item-wrapper {
  cursor: pointer;
  display: inline-block;
}

.pointer {
  cursor:pointer;
}

.cursor-default {
  cursor: default !important;
}

.avatar.medal-item-desc {
  align-items: center;
  display: inline-flex;
  font-size: 0.7rem;
  line-height: 1rem;
  text-align: center;
}

.medal-item-wrapper .avatar:not(.avatar-blue) {
  background-color: transparent;
}

.medal-item-wrapper .avatar-status {
  align-items: center;
  border-width: 1px;
  bottom: -1px;
  color: #fff;
  display: inline-flex;
  font-size: 0.6rem;
  height: 1rem;
  justify-content: center;
  line-height: 0.6rem;
  right: -1px;
  text-align: center;
  width: 1rem;
}

.medal-item-wrapper.tracked {
  border-radius: 3px;
}

.card-table abbr:not(.abbr) {
  text-decoration: none !important;
}

.small-font-size {
  font-size: 0.7rem;
}

@media screen and (min-width: 768px) {
  .small-font-size {
    font-size: 0.9rem;
  }
}

.medal-done {
  align-items: center;
  display: flex;
  font-size: 3rem;
}

.medal-done-value {
  font-size: 1.5rem;
}

.links-name {
  width: 10rem;
}

.text-bold {
  font-weight: 400 !important;
}

.flag.flag-small {
  height: 0.6rem;
  vertical-align: middle;
  width: 0.8rem;
}

.inline-block {
  display: inline-block !important;
}

.flex-center {
  align-items: center;
  display: inline-flex !important;
  justify-content: center;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** Background */
.dark body,
.dark .header input,
.dark .header .btn-secondary,
.dark .tooltip-container {
  background-color: #333;
  color: #fefefe;
}

/* Z-index 1 items */
.dark .header,
.dark .btn-secondary,
.dark input,
.dark .footer,
.dark .card {
  background-color: #000;
  color: #fefefe;
}

.dark .header {
  border-bottom-color: rgb(60,60,60);
}

.dark .alert.alert-primary {
  background-color: #666;
  border-color: #666;
  color: #fefefe;
}

.dark a {
  color: #fff !important;
  font-weight: 600;
}

a.text-red {
  color: #cd201f !important
}

/**
 tooltip
*/

.tooltip-container {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  max-width: 250px;
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
  white-space: normal;
}

.tooltip-arrow {
  display: none;
}

/**
 Compare
*/

.compare-category-graphic.my-4 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.hide {
  display: none !important;
}

.compare-user-remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

.col-sm-3 h2 {
  font-size: 1.1rem;
}

.col-sm-3 .display-4 {
  font-size: 1.5rem;
}

.compare .card {
  height: calc(100% - 1.5rem);
}

.compare .turn {
  display: none;
  margin-bottom: 3rem;
}

@media screen and (max-width: 500px) {
  .compare .card-body {
    padding: 0.2rem;
  }

  .col-sm-3 h2 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .compare .turn {
    display: block;
  }
}

.chart-container {
  height: 400px;
}